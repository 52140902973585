import React from "react";
import "./SecurityLink.scss";
import {changeOverlayContent} from "../../events/OverlayEvents";
import SecurityPopUp from "../HomepageContainer/SecurityPopUp/SecurityPopUp";

const SecurityLink = (props) => {
    const {setPopupVisible} = props

    const openPopup = () => {
        changeOverlayContent(<SecurityPopUp setPopupVisible={setPopupVisible}/>);
        localStorage.removeItem("security2024Hidden");
    };

    return <div onClick={openPopup} id="security-link">
        <i className="fa fa-exclamation-circle"></i>
        <div>Information sécurité</div>
    </div>
}

export default SecurityLink;
